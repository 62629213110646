{
  "LANG": {
    "TITLE": "English",
    "SHORT": "EN"
  },
  "COMMON": {
    "CANCEL": "Cancel",
    "NO_DATA": "No Data",
    "NEXT": "Next",
    "PREVIOUS": "Previous",
    "SELECT": "Select",
    "IN_PROGRESS": "In Progress",
    "FAQ": "FAQ",
    "LITEPAPER": "Litepaper",
    "LEND": "Lend",
    "CURRENT_MARKET_SIZE": "Current Market Size",
    "GO_DASHBOARD": "Go Back to Dashboard",
    "GO_BACK": "Go Back",
    "OR": "Or",
    "MAX": "Max",
    "AMOUNT": "Amount",
    "PROCESS": "Process",
    "APPROVE": "Approve",
    "NEXT_STEP": "Next Step",
    "RESULT_SUCCESSFUL": "{action} Successful",
    "RESULT_FAILED": "{action} Failed",
    "DEPOSITS": "Deposits",
    "BORROWINGS": "Borrowings",
    "TOTAL_BALANCE": "Total Balance",
    "BORROWED": "Borrowed",
    "HEALTH_FACTOR": "Health Factor",
    "BORROW_COMPOSITION": "Borrow Composition",
    "BORROWING_POWER_USED": "Borrowing power used",
    "YOUR_COLLATERAL": "Your Collateral",
    "CURRENT_LTV": "Current LTV",
    "MAX_LTV": "Maximum LTV",
    "LIQUIDATE_THRESHOLD": "Liquidation Threshold",
    "COLLATERAL_COMPOSITION": "Collateral Composition",
    "BORROW": "Borrow",
    "REPAY": "Repay",
    "VARIABLE": "Variable",
    "STABLE": "Stable",
    "DEPOSIT": "Deposit",
    "WITHDRAW": "Withdraw",
    "HISTORY": "History",
    "LEFT": "Left",
    "PROCEED": "Proceed",
    "ERROR": "Error",
    "YES": "Yes",
    "NO": "No",
    "DEPOSIT_NOW": "Deposit now",
    "BORROW_NOW": "Borrow now",
    "REWARD": "Reward",
    "MY_REWARD": "My Rewards",
    "CLAIM": "Claim",
    "STAKING_CLAIM": "Claim",
    "PLATFORM_CLAIM": "Claim",
    "CLAIM-REWARDS": "Claim Rewards",
    "CLAIM-ALL": "Claim All to Wallet",
    "CLAIM-BTN": "Claim All",
    "CONNECT_WALLET_DES": "Connect a wallet to see this feature.",
    "NO_DEPOSIT_TITLE": "Your balance is zero",
    "NO_DEPOSIT_DES": "Your balance of {symbol} is 0. Transfer {symbol} to your wallet to be able to deposit",
    "NO_BORROW_TITLE": "No deposits yet",
    "NO_BORROW_DES": "You need to deposit some collateral first to unlock your borrowing power.",
    "GOVERNANCE": "Governance",
    "GOVERNANCE-DES": "Staking in the governance module allows bMXX holders to govern the Multi-Chain Markets and Reserves",
    "STAKING": "Staking",
    "PROPOSAL": "Proposal",
    "CONNECT_WALLET_DEPOSIT_DES": "Connect a wallet to deposit and see your balance in this dashboard.",
    "CONNECT_WALLET_BORROW_DES": "Connect a wallet to borrow and see your balance in this dashboard.",
    "CONNECT_WALLET_HISTORY_DES": "Connect a wallet to see your history in this dashboard.",
    "CONNECT_WALLET_GOV_DES": "Connect a wallet to see your governance in this dashboard.",
    "CONNECT_WALLET_REWARD_DES": "Connect a wallet to see your reward in this dashboard.",
    "TOTAL": "Total",
    "UNSURE": "Unsure",
    "ID": "ID",
    "OPTIONS": "Options",
    "VIEW_TRANS": "View transaction",
    "NO_LIQUIDITY": "No Liquidity",
    "NO_LIQUIDITY_DESC": "There is no available liquidity to borrow.",
    "NO_LIQUIDITY_DESC_WITHDRAW": "There is no available liquidity to withdraw.",
    "ACTIVATE": "Activate",
    "COOLDOWN": "Cooldown",
    "DAY": "Day | Day | Days",
    "HOUR": "Hour | Hour | Hours",
    "MIN": "Minute | Minute | Minutes",
    "SEC": "Second | Second | Seconds",
    "H": "hr | hr | hrs",
    "M": "min | min | mins",
    "S": "sec | sec | secs",
    "HF_TOO_LOW_TITLE": "Health factor too low",
    "HF_TOO_LOW_DESC": "Deposit more collateral or repay part of your borrowings to increase your health factor and be able to borrow",
    "NO_WALLET_DETECT": "No browser wallet detected.",
    "CONVERT_DES": "MXX (ERC-20) convert to bMXX (BEP-20)",
    "O_FEE_TITLE": "Origination Fee",
    "O_FEE_TITLE_DES": "Users who want to borrow any of the supported digital assets from Multi-Chain Lend (MCL) must deposit collateral that will be locked on the protocol. These assets must be over-collateralized and will enable borrowing up to 75% of that collateral value. These collateral ratios are determined by the protocol and are controlled through the Governance process, which is documented in the Litepaper. Borrow cryptocurrencies and stable-coins with no credit check and fast origination directly on Binance Smart Chain. Borrow Fee starting at 0.001%",
    "O_FEE_TITLE_DES_MORE": "For more information about fee, visit the {0}",
    "COLLATERAL_HINT_TITLE": "Adding and removing assets as collateral",
    "COLLATERAL_HINT_DES": "Decide whether to use a deposited asset as collateral. An asset used as collateral will affect your borrowing power and health factor.",
    "IR_HINT_TITLE_S": "Stable Interest",
    "IR_HINT_DES_S": "Your interest rate will stay the same for the duration of your loan. Recommended for long-term loan periods and for users who prefer predictability.",
    "IR_HINT_TITLE_V": "Variable Interest",
    "IR_HINT_DES_V": "Your interest rate will fluctuate based on the market. Recommended for short-term loans.",
    "AND_MORE": "and more",
    "SWAP": "Swap Borrow Rate",
    "LIQUIDATIONCALL": "Liquidation Call",
    "FLASHLOAN": "FlashLoan"
  },
  "PAGE_HEADER": {
    "CHECKER": "Converter checker",
    "HEALTH-CHECK": "Health Check",
    "HISTORY-V1": "History V1",
    "HISTORY-V2": "History V2"
  },
  "TABLE_COLUMNS": {
    "ASSETS": "Assets",
    "MARKET_SIZE": "Market Size",
    "TOTAL_BORROWED": "Total Borrowed",
    "DEPOSIT_APY": "Deposit APY",
    "CURRENT_BALANCE": "Current Balance",
    "APY": "APY",
    "USE_AS_COLLATERAL": "Use as Collateral",
    "BORROWED": "Borrowed",
    "APR": "APR",
    "INTEREST_TYPE": "Interest Type",
    "DATE_TIME": "Date/Time",
    "TX_ID": "TX ID",
    "CONVERSION_RATE": "Conversion Rate",
    "AMOUNT_TO_CONVERT": "Amount to Convert",
    "CONVERTED_AMOUNT": "Converted Amount",
    "STATUS": "Status",
    "YOUR_WALLET_BALANCE": "Your Wallet Balance",
    "YOUR_BALANCE_IN_PLATFORM": "Your Balance In Platform",
    "DEPOSIT_DES": "To make a deposit, transfer any of these assets to your wallet",
    "BORROW_DES": "To borrow, deposit any of these assets into your wallet.",
    "AVAILABLE_4_YOU": "Available for borrowing",
    "VARIABLE_B_APR": "Variable APR",
    "STABLE_B_APR": "Stable APR",
    "MY_LIQUIDITY_PERCENT": "My Liquidity %",
    "ADDRESS": "Address",
    "HEALTH_FACTOR": "@:COMMON.HEALTH_FACTOR",
    "COLLATERAL": "Collateral",
    "LIQUIDATION_BONUS": "Liquidations Bonus",
    "LOAN_AMOUNT": "Loan Amount",
    "TYPE": "Type",
    "DEBT_TO_COVER": "Debt to cover",
    "RECEIVE_IN": "User's Collateral\n(Select a collateral to receive)",
    "PAY_WITH": "User's Borrowing\n(Select a collateral to pay)",
    "RECEIVE": "Receive",
    "NAME": "Name",
    "LASTCHECK": "Last check",
    "ENDPOINT": "Endpoint",
    "DESCRIPTION": "Description"
  },
  "EMPTY": {
    "NO_BORROW": "No borrowings yet",
    "NO_BORROW_DESC": "There will be a list of all the assets you borrowed. For now it’s empty until you start to borrow.",
    "NO_DEPOSIT": "No deposits yet",
    "NO_DEPOSIT_DESC": "There will be a list of all the assets you deposited and you are earning on. For now it’s empty until you start to deposit."
  },
  "NOT_FOUND": {
    "TEXT": "404 ERROR PAGE",
    "BACK": "Go Back Home"
  },
  "STATUS": {
    "ALL": "ALL",
    "ACTIVE": "Active",
    "FAILED": "Failed",
    "EXPIRED": "Expired",
    "PENDING": "Pending",
    "CLOSED": "Closed",
    "VALIDATING": "Validating",
    "RUNNING": "Running",
    "TIMED_OUT": "Offline"
  },
  "SIDEBAR": {
    "CHECKER": "Checker",
    "HEALTH_CHECK": "Health Check",
    "HISTORY_V1": "History V1",
    "HISTORY_V2": "History V2"
  },
  "VAULT": {
    "VAULT": "Vaults",
    "INCENTIVE_VAULT": "V2 Incentive Reward vault: {amount} bMXX",
    "REWARD_VAULT": "V2 Staking Reward vault: {amount} bMXX"
  },
  "HISTORY": {
    "EVENT_NAME": "Event name:",
    "ASSET": "Asset:",
    "DATE": "Date:",
    "RESET": "Reset",
    "SEARCH": "Search",
    "EXPORT_CSV": "Export CSV",
    "RECORDS": "Records: "
  },
  "WALLET": {
    "COIN98_WALLET": "Coin98 Wallet",
    "PHANTOM_WALLET": "Phantom Wallet",
    "SOLFLARE_WALLET": "Solflare Wallet",
    "SOLLET_WEB": "Sollet Web"
  }
}
